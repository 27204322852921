
/* Popup */
.popup-layer {
    position: absolute;
    z-index: 999;
    &__body {
        display: block;
        width: 100% !important;
        height: auto !important;
    }
    &__content {
        line-height: 0;
    }
    &__foot {
        padding: 6px 12px;
        background-color: #444;
    }
}
@media (max-width: 576px) {
    .popup-layer {
        top: 100px !important;
        left: 12px !important;
        &__inner {
            width: calc(100vw - 24px) !important;
        }
    }
}
